<template>
  <ReportTable
    name="360view"
    item-key="trueIndex"
    :headers="headers"
    :provider="fetch360Projects"
    data-field="data"
    :process-items="processItems"
    :filter-fields="filterFields"
    :copy-to-clipboard="false"
    :hide-show="false"
  >
    <template #actions-left>
      <div class="body-1 text--secondary my-3">360 view</div>
    </template>
    <template #actions-right>
      <Add360ViewDialog :get360Projects="refreshTable" />
      <Edit360ViewDialog v-model="editedItem" @edit="edit360View" />
    </template>
    <template #item.edit="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="editedItem = item"
        >
          <v-icon small> fa-pencil-alt </v-icon>
        </v-btn>
      </ERow>
    </template>
    <template #item.projectName="{ item }">
      {{ item.projectName }}
      <OpenInNewWindowButton
        :target-link="`${$config.public.dashURL}/projects/${item.projectId}/360`"
      />
    </template>
    <template #item.projectId="{ item }">
      <span
        ><nuxt-link
          :to="{
            path: `/360-view/${item.projectId}`,
            query: { type: item.type },
          }"
        >
          <span class="cursor-pointer primary--text"
            >{{ item.projectId }}
          </span>
        </nuxt-link></span
      >
    </template>
    <template #item.delete="{ item }">
      <ERow justify="center" align="center">
        <v-btn
          icon
          color="primary"
          x-small
          target="_blank"
          @click="openDeleteDialog(item)"
        >
          <v-icon small color="red"> fas fa-trash </v-icon>
        </v-btn>
      </ERow>
    </template>
  </ReportTable>
</template>

<script>
import headers from "@/components/360View/360ViewHeaders"
import filterFields from "@/components/360View/360ViewSearchFilters"
import Add360ViewDialog from "@/components/360View/Add360ViewDialog"
import Edit360ViewDialog from "@/components/360View/Edit360ViewDialog"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import axios from "axios"
import ReportTable from "@/components/ReportTable"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  name: "Views360",
  components: {
    OpenInNewWindowButton,
    Add360ViewDialog,
    Edit360ViewDialog,
    ReportTable,
  },
  data() {
    return {
      loading: true,
      editedItem: null,
      filterFields,
      headers,
    }
  },
  computed: {
    ...mapStores(useReportStore),
  },
  methods: {
    async fetch360Projects() {
      const response = await axios.get(
        `${this.$config.public.firebaseDbLink}data/360-list.json`
      )

      return response
    },
    processItems(items) {
      return items.map((element, index) => ({ ...element, trueIndex: index }))
    },
    refreshTable() {
      this.$root.$emit("refresh-report-table")
    },
    async openDeleteDialog(deletedItem) {
      if (
        await this.$confirmDialog.open({
          title: "Delete 360 view",
          message:
            "Are you sure you want to delete this 360 view ?. If you are not from the 3D team please contact them before doing this action",
        })
      ) {
        this.delete360View(deletedItem)
      }
    },
    async delete360View(deleteItem) {
      this.reportStore.items = this.reportStore.items.filter(
        (el) => el.trueIndex !== deleteItem.trueIndex
      )
      const items = [...this.reportStore.items].map(
        ({ trueIndex, ...item }) => ({ ...item })
      )
      try {
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/360-list.json`,
          { ...items }
        )
        await axios.delete(
          `${this.$config.public.firebaseDbLink}data/projects/360/${deleteItem.projectId}.json`
        )
        this.$notifications.success("360 view successfully deleted")
      } catch (error) {
        this.$notifications.error({ text: "Failed to delete 360 view!", error })
      }
      this.refreshTable()
    },
    async edit360View(project360) {
      const { trueIndex, ...data } = project360
      try {
        await axios.put(
          `${this.$config.public.firebaseDbLink}data/360-list/${project360.trueIndex}.json`,
          data
        )

        await axios.patch(
          `${this.$config.public.firebaseDbLink}data/projects/360/${project360.projectId}.json`,
          data
        )
        this.refreshTable()
        this.$notifications.success("360 view successfully edited")
      } catch (error) {
        this.$notifications.error("Failed to edit 360 view!", error)
      } finally {
        this.editedItem = null
      }
    },
  },
}
</script>
