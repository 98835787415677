export default [
  {
    value: "edit",
    text: "",
    width: 40,
  },
  {
    value: "projectName",
    text: "Project Name",
    sortable: true,
  },
  {
    value: "projectId",
    text: "Project ID",
    width: 100,
    sortable: true,
  },
  {
    value: "integrationType",
    text: "Integration Type",
    sortable: true,
    width: 150,
  },
  {
    value: "timeline",
    text: "4D",
    sortable: true,
    width: 150,
  },
  {
    value: "delete",
    text: "",
    sortable: true,
    width: 40,
  },
]
