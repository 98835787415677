import {
  _360IntegrationTypes,
  BooleanTextOptions,
} from "@/components/constants"
export default {
  projectName: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  projectId: {
    component: "TextFieldSearchFilter",
    offline: true,
  },
  integrationType: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: null }, ..._360IntegrationTypes],
      value: null,
    },
    offline: true,
  },
  timeline: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: null }, ...BooleanTextOptions],
      value: null,
    },
    offline: true,
  },
}
