<template>
  <v-dialog v-model="editingDialog" width="500">
    <v-card>
      <v-card-title>
        <span>Edit 360 View</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid }">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="project360.projectName"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project Name"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Project id"
                  rules="required"
                >
                  <v-text-field
                    v-model="project360.projectId"
                    dense
                    disabled
                    outlined
                    :error-messages="errors"
                    label="Project id"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Integration types"
                  rules="required"
                >
                  <v-select
                    v-model="project360.integrationType"
                    :items="integrationTypes"
                    label="Integration types"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol
                v-if="project360.integrationType"
                cols="11"
                class="py-0 mx-auto"
              >
                <v-text-field
                  v-model="project360.providerPrefix"
                  dense
                  outlined
                  label="Provider Prefix"
                />
                <v-text-field
                  v-model="project360.providerSuffix"
                  dense
                  outlined
                  label="Provider Suffix"
                />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Provider ID"
                  rules="required"
                >
                  <v-text-field
                    v-model="project360.providerID"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Provider ID"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="4D timeline"
                  rules="required"
                >
                  <v-select
                    v-model="project360.timeline"
                    :items="yesNoSelect"
                    label="4D timeline"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="editingDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="edit360View">
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  _360IntegrationTypes,
  BooleanTextOptions,
} from "@/components/constants"
export default {
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      project360: {
        projectId: null,
        integrationType: null,
        timeline: null,
        projectName: null,
        apiId: null,
        apiKey: null,
        providerID: null,
        providerPrefix: "",
        providerSuffix: "",
      },
      integrationTypes: _360IntegrationTypes,
      yesNoSelect: BooleanTextOptions,
    }
  },
  computed: {
    editingDialog: {
      get() {
        return !!this.value
      },
      set(value) {
        if (!value) {
          this.$emit("input", null)
        }
      },
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.project360 = { ...value }
      }
    },
  },
  methods: {
    async edit360View() {
      const project360 = {
        ...this.project360,
        uniqueId: `${this.project360.projectId}${this.project360.integrationType}`,
      }
      this.$emit("edit", project360)
    },
  },
}
</script>
